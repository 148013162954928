var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-tabbar",
    {
      staticStyle: { "background-color": "#f13131" },
      attrs: {
        fixed: "",
        route: "",
        "active-color": "#c8aa86",
        "inactive-color": "#ffffff",
      },
      on: { change: _vm.handleChange },
      model: {
        value: _vm.active,
        callback: function ($$v) {
          _vm.active = $$v
        },
        expression: "active",
      },
    },
    _vm._l(_vm.data, function (item, index) {
      return item.isShow
        ? _c(
            "van-tabbar-item",
            {
              key: index,
              attrs: { to: item.to },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function (props) {
                      return [
                        _c("van-icon", {
                          attrs: {
                            name: props.active ? item.activeIcon : item.icon,
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            [_vm._v(" " + _vm._s(item.title) + " ")]
          )
        : _vm._e()
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }